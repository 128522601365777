.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  // background-image: linear-gradient(91.02deg, #e41b1b 0%, #ec5f5f 102.32%);
  background-color: #747474;
  width: 5px;
}

.costum-scrollbar .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  background-color: #747474;
  height: 5px;
}
