.costum-pagination .swiper-pagination {
    position: absolute !important;
    bottom: 10px !important;
    left: 10px !important;
    right: auto !important;
    display: flex !important;
    justify-content: flex-start !important;
}

.costum-pagination .swiper-pagination-bullet {
    border-radius: 50% !important;
    background-color: white !important;
    transition: all 0.3s ease !important;
}

.costum-pagination .swiper-pagination-bullet-active {
    width: 22px !important;
    border-radius: 10px !important;
    background-color: red !important;
}

.costum-nav .swiper-button-disabled {
    display: none !important;
}